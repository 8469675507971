import { useCallback, useEffect, useRef } from 'react';

const dispatchGlobalEvent = (type: 'play' | 'pause') => {
  const event = new CustomEvent('vgtv-action', {
    detail: {
      type,
    },
  });

  document.dispatchEvent(event);
};

// dispatches custom play / pause events to e.g. disable frontpage auto-refresh
export const useGlobalEvent = () => {
  const intervalRef = useRef<number>();

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  const dispatchPlayEvent = useCallback(() => {
    window.clearInterval(intervalRef.current);

    dispatchGlobalEvent('play');

    intervalRef.current = window.setInterval(() => {
      dispatchGlobalEvent('play');
    }, 10_000);
  }, []);

  const dispatchPauseEvent = useCallback(() => {
    dispatchGlobalEvent('pause');

    window.clearInterval(intervalRef.current);
  }, []);

  return { dispatchPlayEvent, dispatchPauseEvent };
};
