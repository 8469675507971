import type { VFC } from 'react';
import { Typography } from 'ui/Typography';
import type { CnpAuthor } from '@vgtv/api-client/lib/cnp_authors';

import { Byline } from '../Byline/Byline';
import { useTranslation } from '../../i18n';

import styles from './Bylines.module.scss';

interface BylineProps {
  authors: CnpAuthor[];
}

export const Bylines: VFC<BylineProps> = ({ authors }) => {
  const { t } = useTranslation();
  return (
    <Typography variant="label" size="small" color="secondary" className={styles.byline}>
      {t('By')}
      {authors.length === 1 && <Byline author={authors[0]}></Byline>}
      {authors.length === 2 && (
        <>
          <Byline author={authors[0]}></Byline>
          {t('and')}
          <Byline author={authors[1]} />
        </>
      )}
      {authors.length !== 1 && authors.length !== 2 && (
        <>
          {authors.slice(0, -1).map((author) => (
            <span key={author.id}>
              <Byline author={author}></Byline>
              {','}
            </span>
          ))}
          {t('and')}
          <Byline author={authors[authors.length - 1]}></Byline>
        </>
      )}
      {'.'}
    </Typography>
  );
};
