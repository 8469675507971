import { useCallback, useEffect, useRef } from 'react';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import { getAssetByline } from 'asset-helpers';

import { trackEvent } from '../../services/pulse';
import { createClickEvent, createViewEvent } from '../../services/pulse/events';

export const useBylineTracking = (asset: SvpAssetAdditional) => {
  const trackingStateRef = useRef<'idle' | 'viewed' | 'clicked'>('idle');

  const bylineName = getAssetByline(asset)?.name;

  useEffect(() => {
    trackingStateRef.current = 'idle';
  }, [asset.id]);

  const trackBylineView = useCallback(() => {
    if (trackingStateRef.current === 'idle') {
      trackEvent(
        createViewEvent({
          name: bylineName!,
          elementType: 'Byline',
        }),
      );

      trackingStateRef.current = 'viewed';
    }
  }, [bylineName]);

  const trackBylineClick = useCallback(() => {
    if (trackingStateRef.current === 'viewed') {
      trackEvent(
        createClickEvent({
          name: bylineName!,
          intent: 'Contact',
          elementType: 'Byline',
        }),
      );

      trackingStateRef.current = 'clicked';
    }
  }, [bylineName]);

  return {
    trackBylineView,
    trackBylineClick,
  };
};
