// src/index.ts
var isHttpError = (err) => {
  return err instanceof Error && err.name === "HttpError";
};
var HttpError = class extends Error {
  constructor(response) {
    const code = response.status || response.status === 0 ? response.status : "";
    const title = response.statusText || "";
    const status = `${code} ${title}`.trim();
    const reason = status ? `status code ${status}` : "an unknown error";
    super(`Request failed with ${reason}`);
    this.name = "HttpError";
    this.response = response;
  }
};
var resolveFetch = () => {
  try {
    if (typeof window !== "undefined" && "fetch" in window) {
      return fetch;
    } else if ("fetch" in globalThis) {
      return fetch;
    }
  } catch (e) {
    console.error('failed to resolve "fetch"', e);
  }
  return void 0;
};
var getSignal = (timeout) => {
  if (!timeout) {
    return {
      signal: void 0,
      clear: () => {
      }
    };
  }
  const controller = new AbortController();
  const timer = setTimeout(() => {
    controller.abort();
  }, timeout);
  const clear = () => {
    clearTimeout(timer);
  };
  return {
    signal: controller.signal,
    clear
  };
};
var createClient = ({
  baseUrl,
  timeout: defaultTimeout = 2e3,
  fetch: fetch2 = resolveFetch()
}) => {
  const normalizedBaseUrl = baseUrl.replace(/\/$/, "");
  return async (url, options = {}) => {
    const { searchParams, timeout = defaultTimeout, ...init } = options;
    const controller = getSignal(timeout);
    let input = `${normalizedBaseUrl}${url}`;
    if (searchParams) {
      const params = new URLSearchParams(searchParams).toString();
      input += `?${params}`;
    }
    const response = await fetch2(input, { ...init, signal: controller.signal });
    controller.clear();
    if (!response.ok) {
      throw new HttpError(response);
    }
    return response;
  };
};
export {
  HttpError,
  createClient,
  isHttpError
};
