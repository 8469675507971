import type { FC } from 'react';
import { Views } from '@vgno/icons';
import { useMemo } from 'react';

import { Typography } from '../../../Typography';

import styles from './Displays.module.scss';

export interface DisplaysProps {
  displays: number;
}

export const Displays: FC<DisplaysProps> = ({ displays }) => {
  const formatted = useMemo(
    () =>
      Intl.NumberFormat('en-US', {
        notation: 'compact',
      }).format(displays),
    [displays],
  );

  return (
    <Typography variant="label" size="small" bold color="secondary" className={styles.displays} title={formatted}>
      <Views />
      {formatted}
    </Typography>
  );
};
