import type { FC } from 'react';
import { BaseButton } from 'ui/BaseButton';

import { useTranslation } from '../../i18n';

import styles from './ShowMoreButton.module.scss';

export const ShowMoreButton: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      ...
      <BaseButton className={styles.button}>&nbsp;{t('See more')}</BaseButton>
    </>
  );
};
