import type { FC } from 'react';

import { Skeleton } from '../../Skeleton/Skeleton';

import styles from './AssetHorizontalSkeleton.module.scss';

export const AssetHorizontalSkeleton: FC = () => {
  return (
    <div className={styles.container}>
      <Skeleton className={styles.thumbnail} />
      <div className={styles.titleContainer}>
        <Skeleton className={styles.title} />
        <Skeleton className={styles.labels} />
      </div>
    </div>
  );
};
