export default {
  'See more': 'Se mer',
  'Next': 'Neste',
  'share': 'del',
  'By': 'Av',
  'and': 'og',
  'Do you have tips?': 'Har du tips til denne saken?',
  'Tip VG': 'Tips VG',
  'Tip the police': 'Tips politiet',
  'Editorial reviews in VG': 'Redaksjonelle vurderinger i VG',
};
