import { getWebStorageItem, setWebStorageItem } from 'web-storage';

type Unpacked<T> = T extends (infer U)[] | readonly (infer U)[] ? U : T;

interface Experiment {
  readonly id: string;
  readonly name?: string;
  readonly variants: readonly string[];
}

export type ResolvedExperiment<T extends Experiment = Experiment> = Omit<T, 'variants'> & {
  variant: Unpacked<T['variants']>;
};

const EXPERIMENTS = {
  'ads-aspect-ratio': {
    id: 'ads-aspect-ratio',
    name: 'Ads aspect ratio',
    variants: ['true'],
  },
} as const;

const getRandomVariant = <T extends string>(variants: readonly T[]): T => {
  return variants[Math.floor(Math.random() * variants.length)];
};

const getStorageKey = (id: string) => {
  return `vivi/${id}`;
};

const experiments = (() => {
  const resolved = Object.keys(EXPERIMENTS).reduce<Record<string, { id: string; name: string; variant: string }>>(
    (acc, key) => {
      const { id, name, variants } = EXPERIMENTS[key as keyof typeof EXPERIMENTS];

      const currentVariant = getWebStorageItem<Unpacked<typeof variants>>(getStorageKey(id));

      acc[key] = {
        id,
        name,
        variant: currentVariant || getRandomVariant(variants),
      };

      return acc;
    },
    {},
  );

  Object.values(resolved).forEach(({ id, variant }) => {
    setWebStorageItem(getStorageKey(id), variant);
  });

  return resolved;
})() as {
  [K in keyof typeof EXPERIMENTS]: ResolvedExperiment<(typeof EXPERIMENTS)[K]>;
};

export const getExperiments = () => experiments;

interface ExternalExperiment {
  readonly key: string;
  readonly id: string;
}

const EXTERNAL_EXPERIMENTS_WHITELIST: ExternalExperiment[] = [];

const externalExperiments = (() => {
  return EXTERNAL_EXPERIMENTS_WHITELIST.reduce<ResolvedExperiment[]>((experiments, experiment) => {
    const variant = getWebStorageItem(experiment.key);

    if (variant) {
      experiments.push({
        id: experiment.id,
        variant,
      });
    }

    return experiments;
  }, []);
})();

export const getExternalExperiments = () => externalExperiments;
