import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import { getAssetMetadata, getAssetTags } from 'asset-helpers';

const formatTagValue = (tag: string) => {
  const escaped = tag
    .trim()
    .toLowerCase()
    .replace(/["'=!+#*~;^()<>@:?%|,.[\]]/g, '')
    .replace(/&/g, 'and')
    .replace(/[-\s]+/g, '-')
    .replace(/å/g, 'aa')
    .replace(/ø/g, 'oe')
    .replace(/æ/g, 'ae');

  return `vg_${escaped}`;
};

export const getKeywords = (asset: SvpAssetAdditional) => {
  const keywords: Record<string, string | string[]> = {
    'no-sno-news-tv_category': String(asset.category.id),
    'no-sno-news-sponsors': getAssetTags(asset).map(formatTagValue),
  };

  const { contentType } = getAssetMetadata(asset);

  if (contentType) {
    keywords['aa-sch-content_type'] = [
      `${formatTagValue(asset.category.title)}_${contentType}`,
      formatTagValue(contentType),
    ];
  }

  return keywords;
};
