import type { FC } from 'react';

import { Typography } from '../../../Typography';

import styles from './Label.module.scss';

export interface LabelProps {
  labels: string[];
  separator?: string;
}

export const DEFAULT_LABEL_SEPARATOR = ' · ';

export const Label: FC<LabelProps> = ({ labels, separator = DEFAULT_LABEL_SEPARATOR }) => {
  const text = labels.join(separator);

  return (
    <Typography variant="label" size="small" bold color="secondary" className={styles.label} title={text}>
      {text}
    </Typography>
  );
};
