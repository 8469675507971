import { ComponentType, DataType } from '@vgtv/api-client/lib/component';
import type { SvpCategoryId } from '@vgtv/api-client/lib/svp_category';
import type { CnpTagId } from '@vgtv/api-client/lib/cnp_tag';
import type { CnpStoryId } from '@vgtv/api-client/lib/cnp_story';
import {
  getLiveAssetFlightTimesStart,
  isDocumentary,
  isUpcomingLive,
  assetHasStory,
  assetHasTag,
  getAssetPublishedDate,
  isEpisode,
} from 'asset-helpers';
import { formatUpcomingDate, formatPublishedDate } from '@vgtv/time-helpers';
import { Provider } from '@vgtv/api-client';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

export interface Context {
  componentType?: ComponentType;
  dataType: DataType;
  categoryId?: SvpCategoryId;
  tagId?: CnpTagId;
  storyId?: CnpStoryId;
}

const defaultContext: Context = {
  dataType: DataType.ASSETS,
};

export const getAssetLabels = (
  asset: SvpAssetAdditional,
  context: Context = defaultContext,
  isNextAsset = false,
): string[] => {
  const { category } = asset;
  const { seasonNumber, episodeNumber } = asset.series ?? {};

  const flags = {
    category: true,
    continue: false,
    next: isNextAsset,
    episode: false,
    series: false,
    eventStart: false,
    publication: false,
  };

  if (context.componentType === ComponentType.CONTINUE_WATCHING && !flags.next) {
    flags.continue = true;
    flags.category = false;
  }

  if (
    flags.next ||
    category.id === context.categoryId ||
    (context.tagId && assetHasTag(asset, context.tagId)) ||
    (context.storyId && assetHasStory(asset, context.storyId))
  ) {
    flags.category = false;
  }

  if (category.isSeries && asset.series) {
    flags.episode = true;
    if (context.componentType !== ComponentType.SLIDER || context.dataType !== DataType.SEASONS) {
      flags.series = true;
    }
  }

  if (isUpcomingLive(asset)) {
    flags.eventStart = true;
  }

  if (!isDocumentary(asset) && !isEpisode(asset) && Object.values(flags).filter(Boolean).length < 2) {
    flags.publication = true;
  }

  const labels = [];

  if (flags.continue) {
    labels.push(asset.provider === Provider.VGTV ? 'fortsett' : 'fortsätta');
  }

  if (flags.category) {
    labels.push(category.title);
  }

  if (flags.next) {
    labels.push(asset.provider === Provider.VGTV ? 'neste' : 'nästa');
  }

  if (flags.series) {
    if (flags.category || flags.continue || flags.next) {
      // shorthand
      labels.push(`S${seasonNumber}, E${episodeNumber}`);
    } else {
      labels.push(
        asset.provider === Provider.VGTV
          ? `Sesong ${seasonNumber}, Episode ${episodeNumber}`
          : `Säsong ${seasonNumber}, Avsnitt ${episodeNumber}`,
      );
    }
  } else if (flags.episode) {
    labels.push(asset.provider === Provider.VGTV ? `Episode ${episodeNumber}` : `Avsnitt ${episodeNumber}`);
  }

  if (flags.eventStart) {
    const start = getLiveAssetFlightTimesStart(asset)!;

    labels.push(
      formatUpcomingDate({
        timestamp: start,
        locale: asset.provider === Provider.VGTV ? 'nb' : 'sv',
        streamType: asset.streamType,
      }),
    );
  }

  if (flags.publication) {
    const publishedDate = getAssetPublishedDate(asset);

    if (publishedDate) {
      labels.push(formatPublishedDate(publishedDate, asset.provider === Provider.VGTV ? 'nb' : 'sv'));
    }
  }

  return labels;
};
