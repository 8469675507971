import type { SvpAssetAdditional, SvpAssetId } from '@vgtv/api-client/lib/svp_asset';
import type { FC, ReactNode } from 'react';
import { createContext, useContext, useMemo, useRef } from 'react';

import type { Vendor } from '../../types';

export interface Storage<K, V> {
  getItem: (key: K) => V | undefined;
  setItem: (key: K, value: V) => void;
  removeItem: (key: K) => void;
}

type AssetKey = `${Vendor}:${SvpAssetId}`;

export type AssetStorage = Storage<AssetKey, SvpAssetAdditional>;

interface AssetCacheProps {
  assetCache: AssetStorage;
}

const AssetCacheContext = createContext<AssetCacheProps>(undefined!);

interface AssetCacheProviderProps {
  storage?: AssetStorage;
  children: ReactNode;
}

export const createAssetStorage = (): AssetStorage => {
  const storage = new Map<AssetKey, SvpAssetAdditional>();

  return {
    getItem: (key) => {
      return storage.get(key);
    },
    setItem: (key, value) => {
      storage.set(key, value);
    },
    removeItem: (key) => {
      storage.delete(key);
    },
  };
};

export const AssetCacheProvider: FC<AssetCacheProviderProps> = ({ storage, children }) => {
  const storageRef = useRef(storage);

  if (!storageRef.current) {
    storageRef.current = createAssetStorage();
  }

  const state = useMemo(() => {
    return {
      assetCache: storageRef.current!,
    };
  }, []);

  return <AssetCacheContext.Provider value={state}>{children}</AssetCacheContext.Provider>;
};

export const useAssetCache = () => useContext(AssetCacheContext);
