// ../svp-api-client/dist/index.js
var SVP_ASSET_ADDITIONAL = [
  "settings",
  "chapters",
  "cuePoints",
  "externalId",
  "externalCategoryId",
  "nextAsset",
  "sourceFiles",
  "subtitles",
  "metadata",
  "access",
  "tags",
  "stories"
].join("|");

// src/index.ts
var getRelatedAssets = (client) => async ({
  assetId,
  limit,
  vendor,
  filters: customFilters = []
}, options) => {
  const searchParams = new URLSearchParams();
  if (limit) {
    searchParams.set("limit", String(limit));
  }
  const filters = ["category.showCategory::true", ...customFilters];
  searchParams.set("filter", filters.join("|"));
  searchParams.set("additional", SVP_ASSET_ADDITIONAL);
  const response = await client(`/${vendor}/${assetId}`, {
    ...options,
    searchParams
  });
  return response.json();
};
var createRelatedApi = (client) => {
  return {
    getRelatedAssets: getRelatedAssets(client)
  };
};
export {
  createRelatedApi
};
