import { type FC } from 'react';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import { Typography } from 'ui/Typography';
import {
  getAssetDisplayTitle,
  getAssetDisplayTags,
  getAssetAgeLimit,
  isSuspectSeries,
  areTipsEnabled,
} from 'asset-helpers';
import { DEFAULT_LABEL_SEPARATOR } from 'ui/Asset';

import { Tag } from '../Tag/Tag';
import { getLabels } from './helpers';
import { useAuthorsGet } from '../../../hooks/useAuthorsGet';
import { Bylines } from '../../Bylines/Bylines';
import { AgeLimitLabel } from '../../AgeLimitLabel/AgeLimitLabel';
import Suspects from '../../Suspects/Suspects';

import styles from './AssetDetails.module.scss';

interface AssetDetailsProps {
  asset: SvpAssetAdditional;
}

export const AssetDetails: FC<AssetDetailsProps> = ({ asset }) => {
  const { authors, areAuthorsFetching } = useAuthorsGet({ asset });
  const title = asset && getAssetDisplayTitle(asset);
  const labels = (asset && getLabels(asset)) ?? [];
  const tags = asset && getAssetDisplayTags(asset);
  const ageLimit = getAssetAgeLimit(asset);
  const displaySuspects = asset && isSuspectSeries(asset) && areTipsEnabled(asset);

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <Typography variant="title" size="large" color="primary" bold className={styles.title}>
          {title}
        </Typography>
        <Typography variant="body" size="medium" bold color="secondary">
          {asset.category.title}
        </Typography>
        {asset.description && (
          <Typography variant="body" size="medium" color="primary">
            {asset.description}
          </Typography>
        )}
        {!areAuthorsFetching && authors.length > 0 && <Bylines authors={authors}></Bylines>}
      </div>
      <div className={styles.labels}>
        <Typography variant="label" size="medium" color="secondary">
          {ageLimit !== null && (
            <>
              <AgeLimitLabel ageLimit={ageLimit} />
              {DEFAULT_LABEL_SEPARATOR}
            </>
          )}
          {labels.join(DEFAULT_LABEL_SEPARATOR)}
        </Typography>
        {Boolean(tags.length) && (
          <div className={styles.tags}>
            {tags.map(({ tag, id }) => (
              <Tag tag={tag} id={id} key={id} />
            ))}
          </div>
        )}
      </div>
      {displaySuspects && <Suspects variant={'desktop'} />}
    </div>
  );
};
