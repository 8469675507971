import type { ForwardedRef, ReactNode, JSXElementConstructor, ComponentProps } from 'react';
import cn from 'clsx';
import { createElement, forwardRef } from 'react';

import styles from './Typography.module.scss';

type Variant = 'headline' | 'title' | 'label' | 'body';

type Size = 'large' | 'medium' | 'small';

export type TypographyProps<
  V extends Variant = 'body',
  S extends Size = 'medium',
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<unknown> = (typeof defaultVariantMapping)[V][S],
> = {
  component?: T;
  variant?: V;
  size?: S;
  bold?: boolean;
  color?: 'primary' | 'secondary' | 'inherit';
  className?: string;
  children?: ReactNode;
} & ComponentProps<T>;

const defaultVariantMapping = {
  headline: {
    large: 'h1',
    medium: 'h2',
    small: 'h3',
  },
  title: {
    large: 'h4',
    medium: 'h5',
    small: 'h6',
  },
  label: {
    large: 'span',
    medium: 'span',
    small: 'span',
  },
  body: {
    large: 'p',
    medium: 'p',
    small: 'p',
  },
} as const;

const BaseTypography = <
  V extends Variant = 'body',
  S extends Size = 'medium',
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<unknown> = (typeof defaultVariantMapping)[V][S],
>(
  {
    component: componentProp,
    variant = 'body' as V,
    size = 'medium' as S,
    color = 'inherit',
    bold,
    className,
    children,
    ...props
  }: TypographyProps<V, S, T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: ForwardedRef<any>,
) => {
  const component = (componentProp ?? defaultVariantMapping[variant][size]) as T;

  return createElement(
    component,
    {
      className: cn(
        styles.typography,
        styles[variant],
        styles[size],
        styles[color],
        { [styles.bold]: bold },
        className,
      ),
      ref,
      ...props,
    },
    children,
  );
};

export const Typography = forwardRef(BaseTypography) as unknown as <
  V extends Variant = 'body',
  S extends Size = 'medium',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any> = (typeof defaultVariantMapping)[V][S],
>(
  props: TypographyProps<V, S, T>,
) => ReturnType<typeof BaseTypography>;
