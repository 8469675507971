import { createRelatedApi } from 'related-api-client';
import { createSvpApi } from 'svp-api-client';
import { createVgtvApi } from 'vgtv-api-client';
import { createClient } from 'http-client';

import { config } from '../config';

export const relatedApi = createRelatedApi(createClient({ baseUrl: config.relatedApi.url }));

export const svpApi = createSvpApi(createClient({ baseUrl: config.svpApi.url }));

export const vgtvApi = createVgtvApi(createClient({ baseUrl: config.vgtv.apiUrl }));
