import { useEffect } from 'react';

export const useScrollLock = (enabled: boolean) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const previous = document.body.style.getPropertyValue('overflow');

    document.body.style.overflow = 'hidden';

    const observer = new MutationObserver(() => {
      document.body.style.overflow = 'hidden';
    });

    observer.observe(document.body, { attributes: true, attributeFilter: ['style'] });

    return () => {
      observer.disconnect();

      document.body.style.overflow = previous;
    };
  }, [enabled]);
};
