import { useEffect, useMemo, useRef, useState } from 'react';
import type { SvpAssetAdditional, SvpAssetId } from '@vgtv/api-client/lib/svp_asset';
import type { Player as SvpPlayer } from '@schibsted-svp/web-player';

import type { Vendor } from '../types';
import { useAssetCache } from '../components/AssetCache/AssetCache';
import { svpApi } from '../services';
import { cloneDeep } from '../utils/cloneDeep';
import { createPlayer, getRawAsset } from '../components/Player/helpers';

const defaultAspectRatio = 16 / 9;

export const usePlayer = ({
  assetId,
  vendor = 'vgtv',
  onAssetReady,
  onPlaybackReadyChange,
}: {
  assetId?: SvpAssetId;
  vendor?: Vendor;
  onAssetReady?: (asset: SvpAssetAdditional) => void;
  onPlaybackReadyChange?: (state: boolean) => void;
}) => {
  const playerNodeId = useMemo(() => `overlay-video-${Date.now()}}`, []);
  const isPlayerReadyRef = useRef(false);
  const [player, setPlayer] = useState<SvpPlayer>();
  const [isAdPlaying, setIsAdPlaying] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(defaultAspectRatio);

  const { assetCache } = useAssetCache();

  useEffect(() => {
    let player: SvpPlayer;

    const getAssetAspectRatio = () => {
      const asset = getRawAsset(player);
      return Number(asset?.additional.metadata.aspectRatio || defaultAspectRatio.toFixed(2));
    };

    (async () => {
      player = await createPlayer({
        config: {
          id: assetId,
          node: playerNodeId,
        },
      });

      player.once('ready', () => {
        isPlayerReadyRef.current = true;
      });

      player.on('adStarted', (data: { aspectRatio?: number }) => {
        const adAspectRatio = data.aspectRatio;
        if (adAspectRatio) {
          setAspectRatio(adAspectRatio);
        }
      });

      player.on('play', () => {
        setAspectRatio(getAssetAspectRatio());
      });

      player.on('assetReady assetError', () => {
        const asset = getRawAsset(player);

        if (!asset) {
          return;
        }

        const handlePlaybackReady = () => {
          player.off('assetPlay adSlotStart autostartNotAllowed error', handlePlaybackReady);

          onPlaybackReadyChange?.(true);
        };

        player.once('assetPlay adSlotStart autostartNotAllowed error', handlePlaybackReady);

        // Set the player size to the aspect ratio of the asset (reduced CLS)
        setAspectRatio(getAssetAspectRatio());

        onAssetReady?.(asset);
      });

      player.on('adSlotStart', () => {
        setIsAdPlaying(true);
      });

      player.on('adSlotComplete playlistItem complete', () => {
        setIsAdPlaying(false);
      });

      player.on('viewable', ({ viewable }) => {
        if (viewable) {
          return;
        }

        player.model.player?.setFullscreen(false);
      });

      setPlayer(player);
    })();

    return () => player?.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!player || !assetId) {
      return;
    }

    if (assetId <= 0) {
      return;
    }

    const currentAssetId = player.getRawAsset()?.id;

    if (assetId === currentAssetId) {
      return;
    }

    onPlaybackReadyChange?.(false);

    const getAssetItem = async () => {
      let asset = assetCache.getItem(`${vendor}:${assetId}`);

      // make sure we have an asset object loaded for brandstudio,
      // otherwise player will try load asset from vgtv newsroom instead
      if (vendor === 'brandstudio' && !asset) {
        asset = await svpApi.asset.getAsset({ assetId, vendor });

        assetCache.setItem(`${vendor}:${assetId}`, asset);
      }

      return asset;
    };

    const handlePlaylistItem = () => {
      player.play();
    };

    getAssetItem().then((asset) => {
      player.playNext(asset ? cloneDeep(asset) : assetId);
    });

    const isPlayerReady = isPlayerReadyRef.current;

    if (!isPlayerReady) {
      // if the player is not ready yet, we have to manually trigger .play() when it's finally loaded
      player.once('playlistItem', handlePlaylistItem);
    }

    return () => {
      if (!isPlayerReady) {
        player.off('playlistItem', handlePlaylistItem);
      }
    };
  }, [player, assetId, vendor, onPlaybackReadyChange, assetCache]);

  return {
    player,
    playerNodeId,
    isAdPlaying,
    aspectRatio,
  };
};
