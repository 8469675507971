// see https://github.schibsted.io/vg/frimand/blob/master/resources/js/utils/device.js
let cachedDevice: string;

export const getDevice = () => {
  if (!cachedDevice) {
    cachedDevice = document.documentElement.dataset.device || 'mobile';
  }

  return cachedDevice;
};

export const isDesktop = () => getDevice() === 'desktop';

export const isTablet = () => getDevice().startsWith('tablet');
