import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';

export type TFunction = <JSX extends boolean = false>(
  key: string,
  interpolationMap?: Record<string, unknown>,
  options?: { JSX?: JSX },
) => JSX extends true ? JSX.Element | string : string;

interface TranslationContextProps {
  locale: string;
  t: TFunction;
}

const TranslationContext = createContext<TranslationContextProps>(undefined!);

interface TranslationProviderProps<T extends string> {
  locale: T;
  translations: Record<T, Record<string, string>>;
  children: ReactNode;
}

const interpolate = (translation: string, interpolationMap: Record<string, unknown>): string => {
  return Object.keys(interpolationMap).reduce((acc, key) => {
    return acc.replace(new RegExp(`{{${key}}}`, 'g'), interpolationMap[key] as string);
  }, translation);
};

export const TranslationProvider = <T extends string>({
  locale,
  translations,
  children,
}: TranslationProviderProps<T>) => {
  const namespace = translations[locale];

  const t = useCallback<TFunction>(
    (key, interpolationMap = {}, { JSX } = {}) => {
      if (key in namespace) {
        const translation = interpolate(namespace[key], interpolationMap);

        if (!JSX) {
          return translation;
        }

        return (
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: translation,
            }}
          />
        ) as unknown as string;
      }

      return key;
    },
    [namespace],
  );

  const state = useMemo(() => {
    return {
      t,
      locale,
    };
  }, [t, locale]);

  return <TranslationContext.Provider value={state}>{children}</TranslationContext.Provider>;
};

export const useTranslation = () => useContext(TranslationContext);
