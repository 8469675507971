import { Typography } from 'ui/Typography';
import { BaseButton } from 'ui/BaseButton';
import type { FC } from 'react';

import { useTranslation } from '../../i18n';
import { useColorScheme } from '../../hooks/useColorScheme';

import styles from './Suspects.module.scss';

interface SuspectsProps {
  variant: 'mobile' | 'desktop';
}

const Suspects: FC<SuspectsProps> = ({ variant }) => {
  const { t } = useTranslation();
  const { theme } = useColorScheme(variant);
  return (
    <div className={styles.container}>
      <Typography variant="body" size="large" color="primary" bold>
        {t('Do you have tips?')}
      </Typography>
      <div className={styles.buttonsContainer}>
        <BaseButton component="a" href="https://www.vg.no/tips-oss" target="_blank" className={styles.button}>
          <Typography size="medium" variant="label" className={styles.tips}>
            {t('Tip VG')}
          </Typography>
        </BaseButton>
        <BaseButton
          component="a"
          href="https://www.politiet.no/tjenester/tips-politiet/"
          target="_blank"
          className={styles.policeButton}
          data-color-scheme={theme}
        >
          <Typography size="medium" variant="label" color="inherit">
            {t('Tip the police')}
          </Typography>
        </BaseButton>
      </div>
      <BaseButton component="a" href="https://www.vg.no/informasjon/redaksjonelle-avgjorelser" target="_blank">
        <Typography size="medium" variant="label" color="primary" className={styles.link}>
          {t('Editorial reviews in VG')}
        </Typography>
      </BaseButton>
    </div>
  );
};

export default Suspects;
