import { createBrowserHistory } from 'history';

import { setupOverlay } from './appRenderer';
import { createAssetStorage } from './components/AssetCache/AssetCache';
import { preloadAssetCache } from './utils/preloadAssetCache';
import { isDesktop, isTablet } from './utils/device';

const assetCacheStorage = createAssetStorage();

setupOverlay({
  containerId: 'vgtv-overlay',
  history: createBrowserHistory(),
  assetCacheStorage,
  variant: isDesktop() || isTablet() ? 'desktop' : 'mobile',
});

preloadAssetCache(assetCacheStorage);
