// src/index.ts
var getWebStorageItem = (key) => {
  try {
    return window.localStorage.getItem(key);
  } catch {
    return null;
  }
};
var setWebStorageItem = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch {
  }
};
var createWebStorage = (key) => {
  const getItem = () => {
    return getWebStorageItem(key);
  };
  const setItem = (value) => {
    setWebStorageItem(key, value);
  };
  return {
    getItem,
    setItem
  };
};
var createJSONWebStorage = (key) => {
  const storage = createWebStorage(key);
  const getItem = () => {
    try {
      const data = storage.getItem();
      if (data) {
        return JSON.parse(data);
      }
    } catch {
    }
    return void 0;
  };
  const setItem = (value) => {
    storage.setItem(JSON.stringify(value));
  };
  return {
    getItem,
    setItem
  };
};
export {
  createJSONWebStorage,
  createWebStorage,
  getWebStorageItem,
  setWebStorageItem
};
