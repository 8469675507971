import { useEffect } from 'react';

import { config } from '../config';
import { deleteTags } from '../services/xandr/xandr';

export const useXandr = (variant: 'desktop' | 'mobile', isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      return () => {
        deleteTags(config.ads.placements[variant].map((placement) => placement.targetId));
      };
    }
  }, [isOpen, variant]);
};
