import { forwardRef, useEffect } from 'react';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import type { Player as SvpPlayer } from '@schibsted-svp/web-player';

import { getAspectRatioBoxPadding } from './helpers';
import { addOrientationChangeListener } from '../../utils/screenOrientation';
import type { Vendor } from '../../types';
import { usePlayer } from '../../hooks/usePlayer';
import { setRef } from '../../utils/reactRef';

import styles from './Player.module.scss';

interface PlayerProps {
  assetId?: SvpAssetAdditional['id'];
  vendor?: Vendor;
  scrollTop?: string;
  onAssetReady?: (asset: SvpAssetAdditional) => void;
  onPlaybackReadyChange?: (state: boolean) => void;
}

const defaultAspectRatio = 16 / 9;

export const Player = forwardRef<SvpPlayer, PlayerProps>(
  ({ assetId, vendor, scrollTop = '0px', onAssetReady, onPlaybackReadyChange }, ref) => {
    const { playerNodeId, player, aspectRatio, isAdPlaying } = usePlayer({
      assetId,
      vendor,
      onPlaybackReadyChange,
      onAssetReady,
    });

    useEffect(() => {
      if (player) {
        setRef(ref, player);
      }
    }, [player]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (!player) {
        return;
      }

      const unsubscribe = addOrientationChangeListener((orientation) => {
        if (player.model.player?.getViewable()) {
          player.model.player.setFullscreen(orientation === 'landscape');
        }
      });

      return () => unsubscribe();
    }, [player]);

    const paddingBottom = getAspectRatioBoxPadding({
      aspectRatio,
      minAspectRatio: defaultAspectRatio,
      scroll: isAdPlaying ? '0px' : scrollTop,
    });

    const fullPlayerSize = getAspectRatioBoxPadding({
      aspectRatio,
      minAspectRatio: defaultAspectRatio,
      scroll: '0px',
    });

    return (
      <>
        <div className={styles.placeholder} style={{ paddingBottom: fullPlayerSize }} />
        <div className={styles.stickyContainer}>
          <div className={styles.playerContainer} style={{ paddingBottom }}>
            <div className={styles.player}>
              <div id={playerNodeId} />
            </div>
          </div>
        </div>
      </>
    );
  },
);

Player.displayName = 'Player';
