import type { FC } from 'react';
import { Cancel } from '@vgno/icons';
import { BaseButton } from 'ui/BaseButton';

import { useHistory } from '../../History/History';

import styles from './GoBackButton.module.scss';

export const GoBackButton: FC = () => {
  const history = useHistory();

  return (
    <BaseButton
      className={styles.button}
      onClick={() => {
        history.back();
      }}
    >
      <Cancel />
    </BaseButton>
  );
};
