import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import { getAssetDisplayTitle } from 'asset-helpers';
import { useEffect } from 'react';

const getPageTitle = (asset: SvpAssetAdditional) => {
  return `${getAssetDisplayTitle(asset)} - VG`;
};

export const syncPageTitle = (asset: SvpAssetAdditional) => {
  document.title = getPageTitle(asset);
};

export const usePageTitle = (enabled: boolean) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const titleToRestore = document.title;

    return () => {
      document.title = titleToRestore;
    };
  }, [enabled]);
};
