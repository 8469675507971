import React from 'react';
import ReactDOM from 'react-dom';
import type { History } from 'history';

import type { AppProps } from './App';
import { App } from './App';
import { HistoryProvider } from './components/History/History';
import { TranslationProvider } from './i18n';
import type { AssetStorage } from './components/AssetCache/AssetCache';
import { AssetCacheProvider } from './components/AssetCache/AssetCache';
import no from './i18n/locales/no';

const translations = {
  no,
};

export const setupOverlay = ({
  containerId,
  history,
  assetCacheStorage,
  variant = 'mobile',
}: {
  containerId: string;
  history: History;
  assetCacheStorage?: AssetStorage;
  variant?: AppProps['variant'];
}) => {
  ReactDOM.render(
    <React.StrictMode>
      <HistoryProvider value={history}>
        <TranslationProvider locale="no" translations={translations}>
          <AssetCacheProvider storage={assetCacheStorage}>
            <App variant={variant} />
          </AssetCacheProvider>
        </TranslationProvider>
      </HistoryProvider>
    </React.StrictMode>,
    document.getElementById(containerId)!,
  );
};
