import type { AstApnTag, AstDefineTagParams } from './types';
import { loadScript } from '../../utils/loadScript';
import { config } from '../../config';

const loadGdpr = () => {
  window.gdpr = window.gdpr || {};
  window.gdpr.allowHidingAds = true;

  return loadScript('https://cogwheel.inventory.schibsted.io/prod/latest/gdpr-bundle.js');
};

const loadAst = () => {
  return loadScript('https://acdn.adnxs.com/ast/ast.js');
};

const loadXandr = async () => {
  await loadAst();
  await loadGdpr();

  return window.apntag as AstApnTag;
};

let xandrPromise: Promise<AstApnTag>;

const initXandr = async () => {
  if (window.apntag) {
    return window.apntag;
  }

  const apntag = await loadXandr();

  apntag.setPageOpts(config.ads.options);

  return apntag;
};

export const getXandr = () => {
  if (xandrPromise) {
    return xandrPromise;
  }

  xandrPromise = initXandr();

  return xandrPromise;
};

export const loadTag = async (tag: AstDefineTagParams) => {
  const apntag = await getXandr();

  const { targetId } = tag;

  apntag.defineTag({ ...tag, keywords: { ...tag.keywords, ...config.ads.options.keywords } });
  apntag.loadTags([targetId]);

  const result = await new Promise<boolean>((resolve) => {
    apntag.onEvent('adLoaded', targetId, () => {
      resolve(true);
    });
    apntag.onEvent('adNoBid', targetId, () => {
      resolve(false);
    });
    apntag.onEvent('adBadRequest', targetId, () => {
      resolve(false);
    });
    apntag.onEvent('adRequestFailure', targetId, () => {
      resolve(false);
    });

    apntag.showTag(tag.targetId);
  });

  apntag.offEvent('adLoaded', targetId);
  apntag.offEvent('adNoBid', targetId);
  apntag.offEvent('adBadRequest', targetId);
  apntag.offEvent('adRequestFailure', targetId);

  return result;
};

export const deleteTags = async (targetIds: string[]) => {
  const apntag = await getXandr();

  // delete tags so they don't interfere with other tags from different publishers
  targetIds.forEach((targetId) => {
    if (apntag.requests) {
      delete apntag.requests.tags[targetId];
    }
  });
};
