import type { FC, MouseEventHandler } from 'react';
import { Share } from '@vgno/icons';
import { BaseButton } from 'ui/BaseButton';
import { useCallback } from 'react';
import type { SvpAssetAdditional, SvpAssetId } from '@vgtv/api-client/lib/svp_asset';
import { getAssetDisplayTitle, getAssetTags } from 'asset-helpers';
import { Typography } from 'ui/Typography';

import { getAssetPageUrl } from '../AssetLink/getAssetPageUrl';
import { trackEvent } from '../../services/pulse';
import { createClickEvent } from '../../services/pulse/events';
import { config } from '../../config';
import { useTranslation } from '../../i18n';
import type { Vendor } from '../../types';

import styles from './ShareButton.module.scss';

interface ShareButtonProps {
  asset?: SvpAssetAdditional;
}

const getAssetShareUrl = ({
  assetId,
  assetTitle,
  vendor,
}: {
  assetId: SvpAssetId;
  assetTitle: string;
  vendor: Vendor;
}) => {
  const url = new URL(`https://${config.vgtv.hostname}`);

  url.searchParams.set('utm_source', 'overlay-share');

  return getAssetPageUrl({ url, assetId, assetTitle, vendor });
};

export const ShareButton: FC<ShareButtonProps> = ({ asset }) => {
  const { t } = useTranslation();

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e) => {
      e.stopPropagation();

      if (!navigator.share || !asset) {
        return;
      }

      trackEvent(
        createClickEvent({
          name: 'OverlayShareClick',
          elementType: 'OverlayShareClick',
          tags: getAssetTags(asset),
        }),
      );

      const assetTitle = getAssetDisplayTitle(asset);

      try {
        await navigator.share({
          title: assetTitle,
          url: getAssetShareUrl({
            assetId: asset.id,
            assetTitle,
            vendor: asset.provider,
          }).toString(),
        });
      } catch (err) {
        if (err instanceof Error && err.name === 'AbortError') {
          return;
        }

        console.error(err);
      }
    },
    [asset],
  );

  return (
    <BaseButton className={styles.button} onClick={handleClick}>
      <Share />
      <Typography variant="label" size="medium">
        {t('share')}
      </Typography>
    </BaseButton>
  );
};
