// src/index.ts
var SVP_ASSET_ADDITIONAL = [
  "settings",
  "chapters",
  "cuePoints",
  "externalId",
  "externalCategoryId",
  "nextAsset",
  "sourceFiles",
  "subtitles",
  "metadata",
  "access",
  "tags",
  "stories"
].join("|");
var ASSET_ID_PATTERN = /^\d+$/;
var isAssetId = (assetId) => {
  return ASSET_ID_PATTERN.test(assetId);
};
var getAsset = (client) => async ({ assetId, vendor }, options) => {
  const searchParams = new URLSearchParams({ appName: "svp-api-client" });
  searchParams.set("additional", SVP_ASSET_ADDITIONAL);
  const response = await client(`/${vendor}/assets/${assetId}`, { ...options, searchParams });
  const asset = await response.json();
  return asset;
};
var searchAssets = (client) => async ({
  limit,
  filter,
  vendor
}, options) => {
  const searchParams = new URLSearchParams({ appName: "svp-api-client" });
  if (limit) {
    searchParams.set("limit", String(limit));
  }
  if (filter) {
    searchParams.set("filter", filter);
  }
  searchParams.set("additional", SVP_ASSET_ADDITIONAL);
  const response = await client(`/${vendor}/search`, { ...options, searchParams });
  const {
    _embedded: { assets }
  } = await response.json();
  return assets;
};
var assetApi = (client) => {
  return {
    getAsset: getAsset(client),
    searchAssets: searchAssets(client)
  };
};
var getCategories = (client) => async ({ vendor }, options) => {
  const response = await client(`/${vendor}/categories`, {
    ...options,
    searchParams: {
      appName: "svp-api-client",
      additional: SVP_ASSET_ADDITIONAL
    }
  });
  const {
    _embedded: { categories }
  } = await response.json();
  return categories;
};
var categoryApi = (client) => {
  return {
    getCategories: getCategories(client)
  };
};
var createSvpApi = (client) => {
  return {
    asset: assetApi(client),
    category: categoryApi(client)
  };
};
export {
  SVP_ASSET_ADDITIONAL,
  createSvpApi,
  isAssetId
};
