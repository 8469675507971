import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

type AssetItem = {
  type: 'asset';
  asset: SvpAssetAdditional;
};

type DisplayAdItem = {
  type: 'display-ad';
};

export type ListItem = AssetItem | DisplayAdItem;

const getAssetItem = (asset: SvpAssetAdditional): AssetItem => {
  return {
    type: 'asset',
    asset,
  };
};

export const getItems = (assets: SvpAssetAdditional[]): ListItem[] => {
  if (assets.length) {
    return [getAssetItem(assets[0]), { type: 'display-ad' }, ...assets.slice(1).map(getAssetItem)];
  }

  return [];
};
