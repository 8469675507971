import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';

import { trackEvent } from '../../services/pulse';
import { createErrorEvent } from '../../services/pulse/events';

interface ErrorBoundaryProps {
  fallback: ReactNode;
  children?: ReactNode;
  onError?: (error: Error, info: ErrorInfo) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    trackEvent(createErrorEvent({ error }));

    this.props.onError?.(error, info);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
