// src/index.ts
var getAuthors = (client) => async ({ ids, vendor }) => {
  const authorsIds = ids.join(",");
  const response = await client(`/${vendor}/authors?ids=${authorsIds}`, {});
  return response.json();
};
var createVgtvApi = (client) => {
  return {
    getAuthors: getAuthors(client)
  };
};
export {
  createVgtvApi
};
