const compilePath = (path: string) => {
  let pattern =
    '^' +
    path
      .replace(/\/*$/, '') // remove trailing slashes
      .replace(/:(\w+)/g, (_, param) => `(?<${param}>\\w+)`);

  if (path.endsWith('*')) {
    pattern += '(?:\\/(.+)|\\/*)$';
  } else {
    pattern += '\\/*$'; // ignore trailing slashes
  }

  return new RegExp(pattern);
};

export const matchPath = <T extends string>(
  pattern: RegExp | string,
  pathname: string,
): { params: Record<T, string> } | undefined => {
  const matcher = typeof pattern === 'string' ? compilePath(pattern) : pattern;

  const match = pathname.match(matcher);

  if (!match) {
    return undefined;
  }

  return {
    params: match.groups as Record<T, string>,
  };
};
