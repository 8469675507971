import { useEffect, useState } from 'react';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import type { CnpAuthor } from '@vgtv/api-client/lib/cnp_authors';

import { vgtvApi } from '../services';

export function useAuthorsGet({ asset }: { asset: SvpAssetAdditional }) {
  const [authors, setAuthors] = useState<CnpAuthor[]>([]);
  const [areAuthorsFetching, setAreAuthorsFetching] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if (!asset.additional.metadata.authors) {
          setAuthors([]);
          setAreAuthorsFetching(false);
          return;
        }
        const ids = JSON.parse(asset.additional.metadata.authors);
        const authors = await vgtvApi.getAuthors({
          ids,
          vendor: asset.provider,
        });

        setAuthors(authors.authors);
        setAreAuthorsFetching(false);
      } catch (err) {
        setAreAuthorsFetching(false);
        if (err instanceof Error && err.name === 'AbortError') {
          return;
        }
        console.error(err);
      }
    })();
  }, [asset.additional.metadata.authors, asset.provider]);

  return {
    authors,
    areAuthorsFetching,
  };
}
