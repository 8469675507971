import type { FC } from 'react';
import { Typography } from 'ui/Typography';

import { getTagPageUrl } from './helpers';

import styles from './Tag.module.scss';

interface TagProps {
  tag: string;
  id: string;
}

export const Tag: FC<TagProps> = ({ tag, id }) => {
  return (
    <Typography
      variant="label"
      size="small"
      color="primary"
      className={styles.tag}
      component="a"
      href={getTagPageUrl({ tag, id })}
    >
      {tag}
    </Typography>
  );
};
