import type { Identity } from '@schibsted/account-sdk-browser/src/identity';
import type { Monetization } from '@schibsted/account-sdk-browser/src/monetization';

let identityInstancePromise: Promise<Identity>;

const initIdentityInstance = () => {
  return new Promise<Identity>((resolve) => {
    if (window.SPiD_Identity) {
      resolve(window.SPiD_Identity);
    } else {
      window.addEventListener('identity-initialized', () => {
        resolve(window.SPiD_Identity!);
      });
    }
  });
};

export const getIdentityInstance = () => {
  if (identityInstancePromise) {
    return identityInstancePromise;
  }

  identityInstancePromise = initIdentityInstance();

  return identityInstancePromise;
};

let monetizationInstancePromise: Promise<Monetization>;

const initMonetizationInstance = () => {
  return new Promise<Monetization>((resolve) => {
    if (window.SPiD_Monetization) {
      resolve(window.SPiD_Monetization);
    } else {
      window.addEventListener('identity-initialized', () => {
        resolve(window.SPiD_Monetization!);
      });
    }
  });
};

export const getMonetizationInstance = () => {
  if (monetizationInstancePromise) {
    return monetizationInstancePromise;
  }

  monetizationInstancePromise = initMonetizationInstance();

  return monetizationInstancePromise;
};
