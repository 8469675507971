// src/formatDuration.ts
var zeroPad = (number, maxLength = 2) => {
  return String(number).padStart(maxLength, "0");
};
var formatDuration = (milliseconds) => {
  const hours = Math.floor(milliseconds / (60 * 60 * 1e3)) % 24;
  const minutes = Math.floor(milliseconds / (60 * 1e3)) % 60;
  const seconds = Math.floor(milliseconds / 1e3) % 60;
  let formatted = "";
  if (hours > 0) {
    formatted += `${hours}:${zeroPad(minutes)}:`;
  } else {
    formatted += `${minutes}:`;
  }
  formatted += zeroPad(seconds);
  return formatted;
};

// ../../node_modules/date-fns/toDate.mjs
function toDate(argument) {
  const argStr = Object.prototype.toString.call(argument);
  if (argument instanceof Date || typeof argument === "object" && argStr === "[object Date]") {
    return new argument.constructor(+argument);
  } else if (typeof argument === "number" || argStr === "[object Number]" || typeof argument === "string" || argStr === "[object String]") {
    return new Date(argument);
  } else {
    return /* @__PURE__ */ new Date(NaN);
  }
}

// ../../node_modules/date-fns/constructFrom.mjs
function constructFrom(date, value) {
  if (date instanceof Date) {
    return new date.constructor(value);
  } else {
    return new Date(value);
  }
}

// ../../node_modules/date-fns/addDays.mjs
function addDays(date, amount) {
  const _date = toDate(date);
  if (isNaN(amount)) return constructFrom(date, NaN);
  if (!amount) {
    return _date;
  }
  _date.setDate(_date.getDate() + amount);
  return _date;
}

// ../../node_modules/date-fns/constants.mjs
var daysInYear = 365.2425;
var maxTime = Math.pow(10, 8) * 24 * 60 * 60 * 1e3;
var minTime = -maxTime;
var millisecondsInDay = 864e5;
var secondsInHour = 3600;
var secondsInDay = secondsInHour * 24;
var secondsInWeek = secondsInDay * 7;
var secondsInYear = secondsInDay * daysInYear;
var secondsInMonth = secondsInYear / 12;
var secondsInQuarter = secondsInMonth * 3;

// ../../node_modules/date-fns/startOfDay.mjs
function startOfDay(date) {
  const _date = toDate(date);
  _date.setHours(0, 0, 0, 0);
  return _date;
}

// ../../node_modules/date-fns/_lib/getTimezoneOffsetInMilliseconds.mjs
function getTimezoneOffsetInMilliseconds(date) {
  const _date = toDate(date);
  const utcDate = new Date(
    Date.UTC(
      _date.getFullYear(),
      _date.getMonth(),
      _date.getDate(),
      _date.getHours(),
      _date.getMinutes(),
      _date.getSeconds(),
      _date.getMilliseconds()
    )
  );
  utcDate.setUTCFullYear(_date.getFullYear());
  return +date - +utcDate;
}

// ../../node_modules/date-fns/differenceInCalendarDays.mjs
function differenceInCalendarDays(dateLeft, dateRight) {
  const startOfDayLeft = startOfDay(dateLeft);
  const startOfDayRight = startOfDay(dateRight);
  const timestampLeft = +startOfDayLeft - getTimezoneOffsetInMilliseconds(startOfDayLeft);
  const timestampRight = +startOfDayRight - getTimezoneOffsetInMilliseconds(startOfDayRight);
  return Math.round((timestampLeft - timestampRight) / millisecondsInDay);
}

// ../../node_modules/date-fns/constructNow.mjs
function constructNow(date) {
  return constructFrom(date, Date.now());
}

// ../../node_modules/date-fns/isSameDay.mjs
function isSameDay(dateLeft, dateRight) {
  const dateLeftStartOfDay = startOfDay(dateLeft);
  const dateRightStartOfDay = startOfDay(dateRight);
  return +dateLeftStartOfDay === +dateRightStartOfDay;
}

// ../../node_modules/date-fns/differenceInDays.mjs
function differenceInDays(dateLeft, dateRight) {
  const _dateLeft = toDate(dateLeft);
  const _dateRight = toDate(dateRight);
  const sign = compareLocalAsc(_dateLeft, _dateRight);
  const difference = Math.abs(differenceInCalendarDays(_dateLeft, _dateRight));
  _dateLeft.setDate(_dateLeft.getDate() - sign * difference);
  const isLastDayNotFull = Number(
    compareLocalAsc(_dateLeft, _dateRight) === -sign
  );
  const result = sign * (difference - isLastDayNotFull);
  return result === 0 ? 0 : result;
}
function compareLocalAsc(dateLeft, dateRight) {
  const diff = dateLeft.getFullYear() - dateRight.getFullYear() || dateLeft.getMonth() - dateRight.getMonth() || dateLeft.getDate() - dateRight.getDate() || dateLeft.getHours() - dateRight.getHours() || dateLeft.getMinutes() - dateRight.getMinutes() || dateLeft.getSeconds() - dateRight.getSeconds() || dateLeft.getMilliseconds() - dateRight.getMilliseconds();
  if (diff < 0) {
    return -1;
  } else if (diff > 0) {
    return 1;
  } else {
    return diff;
  }
}

// ../../node_modules/date-fns/fromUnixTime.mjs
function fromUnixTime(unixTime) {
  return toDate(unixTime * 1e3);
}

// ../../node_modules/date-fns/intlFormat.mjs
function intlFormat(date, formatOrLocale, localeOptions) {
  let formatOptions;
  if (isFormatOptions(formatOrLocale)) {
    formatOptions = formatOrLocale;
  } else {
    localeOptions = formatOrLocale;
  }
  return new Intl.DateTimeFormat(localeOptions == null ? void 0 : localeOptions.locale, formatOptions).format(
    toDate(date)
  );
}
function isFormatOptions(opts) {
  return opts !== void 0 && !("locale" in opts);
}

// ../../node_modules/date-fns/startOfHour.mjs
function startOfHour(date) {
  const _date = toDate(date);
  _date.setMinutes(0, 0, 0);
  return _date;
}

// ../../node_modules/date-fns/isSameHour.mjs
function isSameHour(dateLeft, dateRight) {
  const dateLeftStartOfHour = startOfHour(dateLeft);
  const dateRightStartOfHour = startOfHour(dateRight);
  return +dateLeftStartOfHour === +dateRightStartOfHour;
}

// ../../node_modules/date-fns/isSameYear.mjs
function isSameYear(dateLeft, dateRight) {
  const _dateLeft = toDate(dateLeft);
  const _dateRight = toDate(dateRight);
  return _dateLeft.getFullYear() === _dateRight.getFullYear();
}

// ../../node_modules/date-fns/isThisHour.mjs
function isThisHour(date) {
  return isSameHour(date, constructNow(date));
}

// ../../node_modules/date-fns/isThisYear.mjs
function isThisYear(date) {
  return isSameYear(date, constructNow(date));
}

// ../../node_modules/date-fns/isToday.mjs
function isToday(date) {
  return isSameDay(date, constructNow(date));
}

// ../../node_modules/date-fns/isTomorrow.mjs
function isTomorrow(date) {
  return isSameDay(date, addDays(constructNow(date), 1));
}

// ../../node_modules/date-fns/subDays.mjs
function subDays(date, amount) {
  return addDays(date, -amount);
}

// ../../node_modules/date-fns/isYesterday.mjs
function isYesterday(date) {
  return isSameDay(date, subDays(constructNow(date), 1));
}

// src/i18n.ts
var translations = {
  nb: {
    yesterday: "i g\xE5r",
    today: "i dag",
    tomorrow: "i morgen"
  },
  sv: {
    yesterday: "i g\xE5r",
    today: "i dag",
    tomorrow: "i morgon"
  }
};
var t = (key, locale) => {
  return translations[locale][key];
};

// src/formatPublishedDate.ts
var isRelativeTimeFormatSupported = "RelativeTimeFormat" in Intl;
var formatHourDistance = (date, locale) => {
  const differenceInMinutes = Math.ceil((date.getTime() - Date.now()) / (60 * 1e3));
  const rtf = new Intl.RelativeTimeFormat(locale, {
    numeric: "auto",
    style: "short"
  });
  return rtf.format(differenceInMinutes, "minute");
};
var formatPublishedDate = (timestamp, locale) => {
  const date = fromUnixTime(timestamp);
  const localeOptions = { locale };
  if (isThisHour(date) && isRelativeTimeFormatSupported) {
    return formatHourDistance(date, locale);
  }
  if (isToday(date)) {
    return `${t("today", locale)} ${intlFormat(
      date,
      {
        hour: "numeric",
        minute: "numeric"
      },
      localeOptions
    )}`;
  }
  if (isYesterday(date)) {
    return `${t("yesterday", locale)} ${intlFormat(
      date,
      {
        hour: "numeric",
        minute: "numeric"
      },
      localeOptions
    )}`;
  }
  if (isThisYear(date)) {
    return intlFormat(
      date,
      {
        month: "long",
        day: "numeric"
      },
      localeOptions
    );
  }
  return intlFormat(
    date,
    {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    localeOptions
  );
};

// src/formatUpcomingDate.ts
var formatUpcomingDate = ({
  timestamp,
  locale,
  streamType
}) => {
  const date = fromUnixTime(timestamp);
  const localeOptions = { locale };
  if (isToday(date)) {
    return `${t("today", locale)} ${intlFormat(
      date,
      {
        hour: "numeric",
        minute: "numeric"
      },
      localeOptions
    )}`;
  }
  if (isTomorrow(date)) {
    return `${t("tomorrow", locale)} ${intlFormat(
      date,
      {
        hour: "numeric",
        minute: "numeric"
      },
      localeOptions
    )}`;
  }
  const diff = differenceInDays(date, Date.now());
  if (diff < 7) {
    return intlFormat(
      date,
      {
        weekday: "long",
        hour: "numeric",
        minute: "numeric"
      },
      localeOptions
    );
  }
  if (streamType === "live" && diff < 31) {
    return intlFormat(
      date,
      {
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      },
      localeOptions
    );
  }
  if (isThisYear(date)) {
    return intlFormat(
      date,
      {
        month: "long",
        day: "numeric"
      },
      localeOptions
    );
  }
  return intlFormat(
    date,
    {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    localeOptions
  );
};
export {
  formatDuration,
  formatPublishedDate,
  formatUpcomingDate
};
