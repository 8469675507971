import { useCallback, useEffect, useRef } from 'react';

export const useAbortController = () => {
  const abortControllerRef = useRef<AbortController>();

  useEffect(() => {
    const controller = abortControllerRef.current;

    return () => controller?.abort();
  }, []);

  const getSignal = useCallback(() => {
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController();
    }

    return abortControllerRef.current!.signal;
  }, []);

  return { getSignal };
};
