import { matchPath } from '../../utils/matchPath';
import { getIdentityInstance } from '../schibstedAccount';
import type { ResolvedExperiment } from '../experiments';
import { getExperiments, getExternalExperiments } from '../experiments';

const convertToHashUrl = (href: string) => {
  const url = new URL(href);

  url.hash = url.pathname + url.search;
  url.pathname = '';
  url.search = '';

  return url.toString();
};

export const getOverlayUrl = ({ pathname, href }: { pathname: string; href: string }) => {
  const matchedPath = matchPath<'assetId'>('/video/:assetId/*', pathname);

  return matchedPath ? convertToHashUrl(href) : href;
};

// current VIVI implementation uses hash URLs, we should preserve that in tracking,
// so that we don't have to update every tracking and analytical tool
//
// default Pulse SDK object builder https://github.schibsted.io/spt-dataanalytics/pulse-sdk-js/blob/master/workspaces/sdk/src/builders/objects-browser.ts
export const createObjectBuilder = () => () => {
  if (!document) {
    return {};
  }

  const url = getOverlayUrl(window.location);

  return {
    name: document.title,
    url,
    id: url,
  };
};

export const createProviderBuilder = () => {
  return {
    product: 'vgtv-vivi',
    productTag: 'vgtv-vivi',
    id: 'vg',
  };
};

export const createExperimentsBuilder = () => {
  return [...Object.values(getExperiments() as Record<string, ResolvedExperiment>), ...getExternalExperiments()].map(
    (experiment) => {
      return {
        ...experiment,
        platform: 'vgtv',
      };
    },
  );
};

export const createActorBuilder = async () => {
  try {
    const identity = await getIdentityInstance();

    const userId = await identity.getUserId();

    if (userId) {
      return {
        id: userId,
        realm: 'spid.no',
      };
    }
  } catch {
    // noop
  }

  // pulse CIS sync expects actor to return an object with id,
  // even if the user is logged out
  return {
    id: undefined,
  };
};
