import type { FC } from 'react';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import cn from 'clsx';
import type { SvpAssetAdditional, SvpAssetId } from '@vgtv/api-client/lib/svp_asset';
import type { Player } from '@schibsted-svp/web-player';
import { useScrollLock } from 'ui/useScrollLock';
import { useLatestRef } from 'ui/useLatestRef';

import { Overlay as MobileOverlay } from './components/Overlay/Overlay';
import { Overlay as DesktopOverlay } from './components/desktop/Overlay/Overlay';
import { useHistory } from './components/History/History';
import { matchPath } from './utils/matchPath';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { useGlobalEvent } from './hooks/useGlobalEvent';
import { useMetaThemeColor } from './hooks/useMetaThemeColor';
import { getAssetPagePath, getAssetPageUrl } from './components/AssetLink/getAssetPageUrl';
import type { Vendor } from './types';
import { usePageTitle, syncPageTitle } from './hooks/usePageTitle';
import { useColorScheme } from './hooks/useColorScheme';
import { useXandr } from './hooks/useXandr';

import styles from './App.module.scss';

const matchAssetPagePath = ({ vendor, pathname }: { vendor: Vendor; pathname: string }) => {
  const matchedPath = matchPath<'assetId'>(getAssetPagePath({ vendor }), pathname);

  if (matchedPath) {
    return {
      params: matchedPath.params,
      vendor,
    };
  }

  return undefined;
};

export interface AppProps {
  variant: 'desktop' | 'mobile';
}

export const App: FC<AppProps> = ({ variant }) => {
  const history = useHistory();
  const player = useRef<Player>(null);
  const [assetId, setAssetId] = useState<SvpAssetId>();
  const [vendor, setVendor] = useState<Vendor>();
  const [isOpen, setIsOpen] = useState(false);

  const assetIdRef = useLatestRef(assetId);

  const { dispatchPlayEvent, dispatchPauseEvent } = useGlobalEvent();

  const { theme } = useColorScheme(variant);
  useScrollLock(isOpen);
  useMetaThemeColor(isOpen, theme);
  usePageTitle(isOpen);
  useXandr(variant, isOpen);

  const openOverlay = useCallback(
    (newAssetId: SvpAssetId, vendor: Vendor) => {
      setAssetId((prevAssetId) => {
        // when opened on the same asset we need to trigger .play() as
        // .playNext() won't autoplay already loaded playlist
        if (prevAssetId === newAssetId) {
          player.current?.play();
        }

        return newAssetId;
      });

      setVendor(vendor);
      setIsOpen(true);

      dispatchPlayEvent();
    },
    [dispatchPlayEvent],
  );

  const closeOverlay = useCallback(() => {
    try {
      player.current?.stop();
    } catch {} // eslint-disable-line no-empty

    setIsOpen(false);

    dispatchPauseEvent();
  }, [dispatchPauseEvent]);

  useLayoutEffect(() => {
    const unsubscribe = history.listen(async ({ location }) => {
      const matchedPath =
        matchAssetPagePath({ vendor: 'vgtv', pathname: location.pathname }) ??
        matchAssetPagePath({ vendor: 'brandstudio', pathname: location.pathname });

      if (matchedPath) {
        openOverlay(parseInt(matchedPath.params.assetId, 10) as SvpAssetId, matchedPath.vendor);

        return;
      }

      closeOverlay();
    });

    return () => unsubscribe();
  }, [history, openOverlay, closeOverlay]);

  const handleError = useCallback(() => {
    history.back();
  }, [history]);

  const handleAssetReady = useCallback(
    (asset: SvpAssetAdditional) => {
      // synchronize url for assets started by e.g. player up next feature
      if (assetIdRef.current !== asset.id) {
        const url = getAssetPageUrl({
          url: new URL(window.location.href),
          assetId: asset.id,
          assetTitle: asset.title,
          vendor: asset.provider,
        });

        history.replace(url.pathname + url.search);
      }

      syncPageTitle(asset);
    },
    [assetIdRef, history],
  );

  const Overlay = variant === 'desktop' ? DesktopOverlay : MobileOverlay;

  return (
    <ErrorBoundary fallback={null} onError={handleError}>
      <div
        className={cn(styles.container, { [styles.isOpen]: isOpen })}
        data-color-scheme={theme}
        data-variant={variant}
      >
        <Overlay assetId={assetId} playerRef={player} vendor={vendor} onAssetReady={handleAssetReady} />
      </div>
    </ErrorBoundary>
  );
};
