export type OrientationType = 'landscape' | 'portrait';

export const isScreenOrientationSupported = typeof ScreenOrientation !== 'undefined';

export const getScreenOrientation = (): OrientationType => {
  if (isScreenOrientationSupported) {
    return window.screen.orientation.type.includes('portrait') ? 'portrait' : 'landscape';
  }

  return Math.abs(window.orientation) !== 90 ? 'portrait' : 'landscape';
};

export const addOrientationChangeListener = (listener: (orientation: OrientationType) => void) => {
  let currentOrientation = getScreenOrientation();

  const handler = () => {
    const newOrientation = getScreenOrientation();

    if (currentOrientation === newOrientation) {
      return;
    }

    currentOrientation = newOrientation;

    listener(currentOrientation);
  };

  if (isScreenOrientationSupported) {
    window.screen.orientation.addEventListener('change', handler);
  } else {
    window.addEventListener('orientationchange', handler);
  }

  return () => {
    if (isScreenOrientationSupported) {
      window.screen.orientation.removeEventListener('change', handler);
    } else {
      window.removeEventListener('orientationchange', handler);
    }
  };
};
