import type { Player, Config, Asset } from '@schibsted-svp/web-player';
import { isMock } from 'asset-helpers';

import { loadScript } from '../../utils/loadScript';
import { config as appConfig } from '../../config';
import { getIdentityInstance, getMonetizationInstance } from '../../services/schibstedAccount';
import { getOverlayUrl } from '../../services/pulse/builders';
import type { ResolvedExperiment } from '../../services/experiments';
import { getExperiments, getExternalExperiments } from '../../services/experiments';

const loadPlayer = (url: string) => {
  return new Promise<void>((resolve) => {
    if (!document.querySelector(`script[src="${url}"]`)) {
      loadScript(url);
    }

    if (window.SVP) {
      resolve();
    } else {
      window.addEventListener('onSvpPlayerReady', () => resolve());
    }
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const pulseDecorator = ((event: Record<string, any>, asset: Asset) => {
  const assetId = asset.get('id');

  return {
    ...event,
    object: {
      ...event.object,
      page: {
        '@id': 'sdrn:vg:frontpage:front',
        '@type': 'Frontpage',
        'url': `${window.location.origin}/#/video/${assetId}`,
      },
      url: getOverlayUrl(window.location),
    },
  };
}) as unknown as Exclude<Config['pulse'], undefined>['decorator'];

export const createPlayer = async ({ config }: { config: Partial<Config> }) => {
  await loadPlayer(appConfig.player.url);

  const playerConfig = window.SVP!.getPlayerConfig(
    {
      site: 'vgtv',
      context: 'portal',
      paywallAccess: {
        enabled: true,
        getSpidIdentityInstance: getIdentityInstance,
        getSpidMonetizationInstance: getMonetizationInstance,
      },
      experiments: [
        ...Object.values(getExperiments() as Record<string, ResolvedExperiment>),
        ...getExternalExperiments(),
      ],
    },
    {
      ...config,
      api: appConfig.svpApi.url,
      pulse: {
        decorator: pulseDecorator,
      },
    },
  );

  return new window.SVP!.Player(playerConfig);
};

export const getRawAsset = (player: Player) => {
  const asset = player.getRawAsset();

  // make sure we skip mock asset and not-yet-fetched assets
  if (asset && (!asset.title || isMock(asset))) {
    return undefined;
  }

  return asset;
};

const isVerticalAspectRatio = (aspectRatio: number) => {
  return String(aspectRatio) === (9 / 16).toFixed(2);
};

const convertAspectRatio = (aspectRatio: number) => {
  // force 2/3 aspect ratio for vertical videos to save space
  if (isVerticalAspectRatio(aspectRatio)) {
    return (3 / 2) * 100;
  }

  return (1 / aspectRatio) * 100;
};

export const getAspectRatioBoxPadding = ({
  aspectRatio,
  minAspectRatio,
  scroll,
}: {
  aspectRatio: number;
  minAspectRatio: number;
  scroll: string;
}) => {
  return `max(calc(${convertAspectRatio(aspectRatio)}% - ${scroll}), ${convertAspectRatio(minAspectRatio)}%)`;
};
