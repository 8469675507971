export const loadScript = (src: string) => {
  return new Promise<void>((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    const onload = () => {
      resolve();
      script.removeEventListener('load', onload);
      document.body.removeChild(script);
    };

    script.addEventListener('load', onload);

    document.body.appendChild(script);
  });
};
