import type { FC } from 'react';

import { Skeleton } from '../../Skeleton/Skeleton';

import styles from './AssetVerticalSkeleton.module.scss';

export const AssetVerticalSkeleton: FC = () => {
  return (
    <div>
      <Skeleton className={styles.thumbnail} />
      <Skeleton className={styles.title} />
      <Skeleton className={styles.labels} />
    </div>
  );
};
