import type { ForwardedRef } from 'react';

export const setRef = <T>(ref: ForwardedRef<T>, instance: T) => {
  if (!ref) {
    return;
  }

  if (typeof ref === 'function') {
    ref(instance);
  } else {
    ref.current = instance;
  }
};
