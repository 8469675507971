import { useLayoutEffect } from 'react';

import type { ColorScheme } from './useColorScheme';

export const useMetaThemeColor = (enabled: boolean, theme: ColorScheme) => {
  useLayoutEffect(() => {
    if (!enabled) {
      return;
    }

    const restore = [...document.querySelectorAll<HTMLMetaElement>('meta[name=theme-color]')].map((meta) => {
      const prevValue = meta.getAttribute('content')!;

      meta.setAttribute('content', theme === 'dark' ? '#1c1c1e' : '#dd0000');

      return () => {
        meta.setAttribute('content', prevValue);
      };
    });

    return () => {
      restore.map((cb) => cb());
    };
  }, [enabled, theme]);
};
