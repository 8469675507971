import type { AstDefineTagParams, AstPageOptionsParams } from './services/xandr/types';

export interface Config {
  relatedApi: {
    url: string;
  };
  svpApi: {
    url: string;
  };
  vgtv: {
    hostname: string;
    apiUrl: string;
  };
  player: {
    url: string;
  };
  ads: {
    options: AstPageOptionsParams;
    placements: {
      desktop: AstDefineTagParams[];
      mobile: AstDefineTagParams[];
    };
  };
}

export const config: Config = {
  relatedApi: {
    url: 'https://related.stream.schibsted.media',
  },
  svpApi: {
    url: 'https://svp.vg.no/svp/api/v1/',
  },

  vgtv: {
    apiUrl: 'https://api.vgtv.no',
    hostname: 'tv.vg.no',
  },
  player: {
    url: 'https://vgc.no/player/player.next.min.bundled-latest.js',
  },
  ads: {
    options: {
      member: 9700,
      keywords: {
        'aa-sch-country_code': 'no',
        'aa-sch-publisher': 'vgtv',
        'no-sno-publishergroup': 'schibsted',
        'aa-sch-inventory_type': 'other',
        'aa-sch-page_type': 'other',
        'no-sno-news-section': 'vgtv_tv',
      },
    },
    placements: {
      desktop: [
        {
          targetId: 'companion-ad',
          invCode: 'no-vgtv-wde-other_topboard',
          sizes: [980, 150],
          keywords: {
            'no-sno-adformat': 'topboard',
            'aa-sch-supply_type': 'web_desktop',
          },
        },
      ],
      mobile: [
        {
          targetId: 'companion-ad',
          invCode: 'no-vgtv-wph-other_board_1',
          sizes: [320, 150],
          keywords: {
            'no-sno-adformat': 'board_1',
            'aa-sch-supply_type': 'web_phone',
          },
        },
      ],
    },
  },
};
