import { useEffect, useState } from 'react';

export type ColorScheme = 'light' | 'dark';

const getColorScheme = (variant: 'desktop' | 'mobile'): ColorScheme => {
  if (variant === 'mobile') {
    return 'dark';
  }

  return document.documentElement.dataset.colorScheme === 'dark' ? 'dark' : 'light';
};

export const useColorScheme = (variant: 'desktop' | 'mobile') => {
  const [theme, setTheme] = useState<ColorScheme>(() => getColorScheme(variant));

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setTheme(getColorScheme(variant));
    });

    observer.observe(document.documentElement, { attributes: true, attributeFilter: ['data-color-scheme'] });

    return () => observer.disconnect();
  }, [variant]);

  return { theme };
};
